import * as React from "react";
import Layout from "../components/layout";
import Configuration from "../Configuration";
import Seo from "../components/seo";
import { Button, Card, Profile } from "@launchacademy/voyager";

import buildImage from "../services/buildImage";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";

import { faChevronRight, faPhone } from "@fortawesome/free-solid-svg-icons";
import BookInfoCallButton from "../components/infoCall/BookInfoCallButton";

import "../css/accent-images/team.css";
const Team = ({ data, location }) => {
  const teamMemberListItems = (data.teamMembers?.nodes || []).map((teamMember) => {
    return (
      <Profile
        name={`${teamMember.firstName} ${teamMember.lastName}`}
        Image={buildImage(teamMember.headshot?.localFile)}
        currentTitle={teamMember.jobTitle}
        description={teamMember.bio}
        profileType="teamMember"
      />
    );
  });
  return (
    <Layout>
      <Seo
        title="The Team Behind Launch Academy"
        pathname={location.pathname}
        description="Get to know the folks that will be helping you to launch your software development career."
      />
      <section className="hero">
        <div className="hero__image-column">
          <div className="hero__image-container">
            <GatsbyImage
              image={getImage(data.staffAndStudents)}
              className="hero__image hero__image_square"
              alt="students and staff posing for a picture"
            />
          </div>
        </div>
        <div className="hero__supporting-content">
          <h2 className="hero__image_title">We’re ready to show you the way to a better future</h2>
          <p className="hero__image_text">
            Since 2013, our team has been passionately focused on changing student’s lives through
            new careers in software engineering. We are eager to shape the skills, minds and careers
            of the newest generation of software developers. Patience and empathy are qualities
            equally as important to us as technical prowess. We are always tinkering and finding new
            ways to improve our education. Our agile approach and the changing technical needs of
            our hiring partners ensure our curriculum and the technologies we teach will remain
            interesting and exciting.
            <br />
            Our community of students, alumni, instructors, career service professionals and
            supporting faculty will support you along each stage of your learning journey and well
            beyond graduation.{" "}
          </p>
          <div className="hero__button-container">
            <BookInfoCallButton
              text="Schedule an Info Call"
              icon={faPhone}
              className="hero__button"
              location={location}
              size="lg"
            />
          </div>
        </div>
      </section>
      <div className="profile-list place-items-start container md:gap-16 lg:gap-32 mb-16">
        {teamMemberListItems}
      </div>
      <section className="bg-secondary-brand">
        <div className="constrained-container hero team_concluding-banner">
          <div className="hero__supporting-content py-10">
            <h3 className="pb-10">
              “It was a judgment free-zone;
              <br />
              <br />
              I felt zero shame if I couldn’t get my head around a certain programming concept.
              <br />
              <br />
              There were no 'dumb' questions. Our mentors (who are geniuses in my eyes) never made
              it feel like they were above us.”
            </h3>

            <div className="md:flex pb-10">
              <GatsbyImage
                image={getImage(data.sidneyCastro)}
                className="rounded-full"
                alt="SidneyCastro: Launch Academy Alumna"
              />
              <div className="ml-4 md:flex-grow">
                <h6 className="font-bold font-headline leading-snug uppercase">Sidney Castro</h6>
                <h5 className="font-text text-base uppercase">Web Producer @ eChalk</h5>
              </div>
            </div>
            <div className="hero__button-container">
              <Button
                to="/about-launch-academy"
                text="Learn About Launch"
                icon={faChevronRight}
                className="banner__button mr-8"
                size="lg"
              />
              <Button
                to={Configuration.applicationBaseUrl}
                text="Apply Today"
                icon={faChevronRight}
                className="banner__button button_secondary"
                size="lg"
              />
            </div>
          </div>
          <div className="hero__image-column">
            <div className="hero__image-container relative">
              <GatsbyImage
                image={getImage(data.launchAcademyShirts)}
                alt="Launch Academy T-Shirts"
                className="hero__accent-top-right"
              />
              <GatsbyImage
                image={getImage(data.studentsHangingOut)}
                className="hero__image"
                alt="student workstation with multiple monitors"
              />
              <GatsbyImage
                image={getImage(data.threeStudentsCollaborating)}
                className="hero__accent-bottom-left"
                alt="three students working together on the floor"
              />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Team;

export const pageQuery = graphql`
  query {
    staffAndStudents: file(relativePath: { regex: "/team/staffAndStudents.jpg/" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    launchAcademyShirts: file(relativePath: { regex: "/launch-academy-shirts.png/" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 390, height: 370)
      }
    }
    threeStudentsCollaborating: file(
      relativePath: { regex: "/team/threeStudentsCollaborating.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 220, height: 260)
      }
    }
    studentsHangingOut: file(relativePath: { regex: "/team/studentsHangingOut.jpg/" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 450, height: 450)
      }
    }
    sidneyCastro: file(relativePath: { regex: "/sidney-castro/" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 64
          height: 64
          transformOptions: { cropFocus: CENTER }
        )
      }
    }
    teamMembers: allStrapiTeamMember {
      nodes {
        bio
        headshot {
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: FIXED
                width: 275
                height: 275
                transformOptions: { grayscale: true }
              )
            }
          }
        }
        jobTitle
        lastName
        firstName
      }
    }
  }
`;
