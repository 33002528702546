import React, { useEffect, useMemo, useRef, useState } from "react";
import useModal from "../../modal/useModal";
import Modal from "../../modal/Modal";
import Configuration from "../../../Configuration";

import "../css/calendlyModal.css";

const useCalendlyModal = ({ location, url, header = null }) => {
  const { isModalVisible, setModalVisibility } = useModal({ scrollToTop: false });
  const [calendlyInitialized, setCalendlyInitialized] = useState(false);
  const { search, state } = location;
  const divRef = useRef();

  useEffect(() => {
    if (!window.Calendly && !calendlyInitialized) {
      const tag = document.createElement("script");
      tag.async = true;
      tag.src = "https://assets.calendly.com/assets/external/widget.js";
      const body = document.getElementsByTagName("body")[0];
      body.appendChild(tag);
    }
    window.addEventListener("message", (e) => {
      if (e.data.event && e.data.event.indexOf("calendly")) {
        setModalVisibility(false);
      }
    });
    setCalendlyInitialized(true);
  }, [location, calendlyInitialized, setCalendlyInitialized]);

  useEffect(() => {
    if (isModalVisible) {
      window.Calendly.initInlineWidget({
        url: `${url}&hide_event_type_details=1`,
        parentElement: divRef.current,
      });
    }
  }, [isModalVisible, location]);

  const modal = useMemo(() => {
    return (
      <Modal
        size="large"
        isVisible={isModalVisible}
        hide={() => {
          setModalVisibility(false);
        }}
      >
        {header}
        <div ref={divRef} className="calendly-inline-widget" data-auto-load="false" />
      </Modal>
    );
  }, [Modal, setModalVisibility, isModalVisible]);

  return { modal, isModalVisible, setModalVisibility };
};

export default useCalendlyModal;
