import React from "react";
import { Button, Icon } from "@launchacademy/voyager";
import useCalendlyModal from "../calendlyModal/hooks/useCalendlyModal.js";
import "@launchacademy/voyager/dist/css/theme/buttons/buttons.css";
import Configuration from "../../Configuration.js";

const BookInfoCallButton = ({
  location,
  text = "Schedule Info Call",
  className = "",
  size,
  icon,
  secondary,
  placement,
}) => {
  const header = (
    <>
      <h2>Book an Info Call</h2>
      <p className="pt-4">
        Questions about learning at Launch Academy or more generally, software development? Learn
        more by scheduling a call with our Co-Founder, below.
      </p>
    </>
  );
  const { modal, setModalVisibility } = useCalendlyModal({
    location,
    url: Configuration.infoCallCalendlyUrl,
    header,
  });

  return (
    <>
      <Button
        to="#bookInfoCall"
        state={{ showInfoCallDialog: true }}
        icon={icon}
        onClick={(e) => {
          e.preventDefault();
          setModalVisibility(true);
        }}
        secondary={secondary}
        placement={placement}
        className={`${className}`}
        text={text}
        size={size}
      />
      {modal}
    </>
  );
};

export default BookInfoCallButton;
